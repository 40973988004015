import { Peoples, Intersection, Search, System, Comment, SettingTwo } from '@icon-park/react'
import { TabBar } from 'antd-mobile'
import { useNavigate, useLocation } from 'react-router-dom'

export default function Navbar(props) {
  const { allUnreadMsgCount } = props

  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const tabs = [
    {
      key: '/discover',
      title: '发现',
      icon: <System theme="outline" size="24" />,
    },
    {
      key: '/match',
      title: '遇见',
      icon: <Peoples theme="outline" size="24" />,
    },
    {
      key: '/chat',
      title: '对话',
      icon: <Comment theme="outline" size="24" />,
      badge: allUnreadMsgCount ? (allUnreadMsgCount > 99 ? '99+' : '' + allUnreadMsgCount) : null,
    },
    {
      key: '/settings',
      title: '设置',
      icon: <SettingTwo theme="outline" size="24" />,
    },
  ]

  function handleChangeTab(value) {
    navigate(value)
  }

  return (
    <div style={{ backgroundColor: 'var(--theme-window-bgcolor)', height: '56px', paddingTop: 6 }}>
      <TabBar activeKey={pathname} onChange={handleChangeTab}>
        {tabs.map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge} />
        ))}
      </TabBar>
    </div>
  )
}
