import { connect } from 'react-redux'
import { useState, useEffect, useMemo, useRef } from 'react'
import Navbar from './Navbar'
import DialogList from '@/pages/DialogList'
import UserSettings from '@/pages/UserSettings'
import DialogDetail from '@/pages/DialogDetail'
import GroupChatEditor from '@/components/group-chat/GroupChatEditor'
import { setUser } from '@/redux/actions/user'
import { setUserInfoCache, setUserMapMany, updateUserInfoCache } from '@/redux/actions/userMap'
import { getCurrUserApi, getUserBaseInfoApi } from '@/services/user'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Popover, Modal, Toast, Button, Dialog } from 'antd-mobile'
import { PubSub } from 'pubsub-js'
import { MoreOne, Help } from '@icon-park/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { initWebSocket, closeWebSocket, wsSetMsgsIsRead, wsSetChatIsRead } from '@/services/websocket'
import {
  addChat,
  setLastMsg,
  changeUnreadCount,
  deleteChat,
  otherDeleteChat,
  clearChats,
  updateChat,
  setGroupUids,
} from '@/redux/actions/chatMap'
import { updateMsgsStatus, insertMsgs, clearMsgs, deleteChatMsgs, deleteMsg } from '@/redux/actions/messageMap'
import {
  clearChatsApi,
  getChatApi,
  getChatInfoApi,
  getGroupChatUsersInfoApi,
  loadNewMessagesApi,
  startChatApi,
} from '@/services/chat'
import cookie from 'react-cookies'

import CrmPage from '@/pages/crm/CrmPage'
import DebugPage from '@/pages/DebugPage'
import ActivePage from '@/pages/ActivePage'
import PromotePage from '@/pages/PromotePage'
import Notify from '@wcjiang/notify'
import './Layout.css'
import MatchPanel from '@/pages/MatchPanel'
import Discover from '@/pages/Discover'
import Register from '@/components/common/Register'
import TermOfService from '@/pages/TermOfService'
import { setDarkTheme } from '@/utils/theme'
import store from '@/redux/store'
import Tips from '@/components/common/Tips'
import VipService from '@/pages/VipService'
import BannedUsers from '@/pages/BannedUsers'
import UserProfile from '@/components/user-profile/UserProfile'
import ActiveByExam from '@/pages/ActiveByExam'
import CheckReportPage from '@/pages/crm/CheckReportPage'
import CheckBottlePage from '@/pages/crm/CheckBottlePage'
import ActiveByWx from '@/pages/ActiveByWx'
import { isUC } from '@/utils/common'
import { appInfo } from '@/const/config'

const Layout = function (props) {
  const {
    user,
    setUser,
    updateMsgsStatus,
    insertMsgs,
    chatMap,
    setLastMsg,
    addChat,
    changeUnreadCount,
    messageMap,
    clearChats,
    clearMsgs,
    deleteMsg,
    deleteChat,
    otherDeleteChat,
    setUserInfoCache,
    setUserMapMany,
    updateUserInfoCache,
    deleteChatMsgs,
    setGroupUids,
  } = props

  const navigate = useNavigate()
  const location = useLocation()
  const [currChat, setCurrChat] = useState(null)
  const [showNav, setShowNav] = useState(true)

  const [broadcast, setBroadcast] = useState(null)
  const [createGroupChatVisible, setCreateGroupChatVisible] = useState(false)
  const [showUserInfo, setShowUserInfo] = useState(false)

  const [registerVisbile, setRegisterVisbile] = useState(false)
  const dialogRef = useRef(null)

  let notify = null
  const audioRef = useRef(null)
  const userRef = useRef(null)

  useEffect(() => {
    window.openNotifySound = user.notify_sound
  }, [user])

  async function initSystem(data) {
    if (!data) return
    console.log('currUid: ', data.id)
    setUser(data)
    if (data.is_vip) {
      setTimeout(() => {
        Toast.show({ content: 'vip校验通过', duration: 1000 })
      }, 2000)
    }
    userRef.current = data
    if (cookie.load('is_debug')) {
      closeWebSocket()
      return
    }
    initWebSocket(data.id, data.token)
  }

  // 加载主题
  useEffect(() => {
    if (Number(cookie.load('isDark') || '0')) {
      setDarkTheme()
    }
    if (location.pathname.includes('/promote')) {
      setShowNav(false)
    }
    // const hostname = window.location.hostname
    // // 老的网址跳转到介绍页
    // if (['www.nmliao.cc', 'www.yuanqilt.com'].indexOf(hostname) !== -1) {
    //   setShowNav(false)
    // }
  }, [])

  useEffect(() => {
    // const hostname = window.location.hostname
    // // 老的网址跳转到介绍页
    // if (['www.nmliao.cc', 'www.yuanqilt.com'].indexOf(hostname) !== -1) {
    //   navigate('/promote/')
    //   return
    // }

    if (['/promote/', '/tos', '/banned_users', '/debug', '/gateway'].indexOf(window.location.pathname) !== -1) return

    async function getUser() {
      const { data, code, msg } = await getCurrUserApi(cookie.load('is_debug'))
      // 未登录，提示登录或注册
      if (code === 401) {
        if (window.location.pathname !== '/discover') navigate('/discover')
        setRegisterVisbile(true)
        Toast.show({ content: msg, duration: 2000, icon: 'fail' })
        return
      } else if (code === 444) {
        Dialog.alert({
          closeOnMaskClick: true,
          title: '该账号已被封禁',
          content: msg,
        })
      } else if (code !== 200) {
        Toast.show({ content: msg, duration: 2000, icon: 'fail' })
        return
      }
      initSystem(data)
    }
    getUser()

    return () => {
      closeWebSocket()
    }
  }, [])

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        notify?.setTitle('')
      }
    }
    document.addEventListener('visibilitychange', onVisibilityChange, false)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange, false)
    }
  }, [])

  useEffect(() => {
    let wsBus = PubSub.subscribe('message', (topic, wsMsg) => {
      switch (wsMsg.func) {
        case 'login':
          return handleLoginResult(wsMsg)
        // case 'ws_onclose':
        //   return Toast.show({ content: '连接已关闭成功', duration: 1000 })
        case 'update_msgs_status':
          return updateMsgsStatus(wsMsg.data.chatid, wsMsg.data.message_ids, wsMsg.data.status)
        case 'recv_msg':
          return handleRecvMsg(wsMsg)
        case 'chat_is_deleted':
          return handleDeleteChat(wsMsg)
        case 'ur_kicked_out':
          return handleDeleteChat(wsMsg)
        case 'chat_is_block':
          return handleBlockChat(wsMsg)
        case 'match_result':
          // 完善后端匹配过程
          return handleMatchResult(wsMsg)
        case 'set_offline':
          return handleSetOffline(wsMsg)
        case 'disconnect':
          return Toast.show({ content: '断开连接，正在重连', duration: 2000 })
        case 'load_new_messages':
          return handleLoadNewMessages(wsMsg)
        case 'user_status_change':
          return handleUserStatusChange(wsMsg)
        case 'delete_msg':
          return handleDeleteMsg(wsMsg)
        case 'login_room':
          return handleSetGroupOnlineUids(wsMsg)
        case 'logout_room':
          return handleSetGroupOnlineUids(wsMsg)
        case 'join_room':
          return handleJoinLeaveRoom(wsMsg)
        case 'leave_room':
          return handleJoinLeaveRoom(wsMsg)
        case 'delete_chat_manual':
          return handleDeleteChat(wsMsg)
        case 'init_system':
          return handleInitSystem(wsMsg)
        default:
          return
      }
    })

    return () => {
      PubSub.unsubscribe(wsBus)
    }
  }, [])

  function handleInitSystem(wsMsg) {
    setBroadcast(wsMsg.data)
  }

  function handleJoinLeaveRoom(wsMsg) {
    const { chatid, user_info } = wsMsg.data
    if (!currChat || currChat.chatid !== chatid) return
    let opt = 'join'
    if (wsMsg.func === 'leave_room') {
      opt = 'leave'
    }
    // 更新用户缓存
    setUserMapMany([user_info])
    // 处理房间成员数
    const tmp = opt === 'join' ? 'add' : 'remove'
    setGroupUids(chatid, [user_info.id], tmp, 'member')
    if (tmp === 'remove') setGroupUids(chatid, [user_info.id], tmp, 'online')
  }

  function handleSetGroupOnlineUids(wsMsg) {
    const { chatid, uid } = wsMsg.data
    if (!currChat || currChat.chatid !== chatid) return
    let opt = 'add'
    if (wsMsg.func === 'logout_room') {
      opt = 'remove'
    }
    setGroupUids(chatid, [uid], opt, 'online')
  }

  function handleDeleteMsg(wsMsg) {
    const { chatid, message_id } = wsMsg.data
    deleteMsg(chatid, message_id)
  }

  function handleLoginResult(wsMsg) {
    if (wsMsg.code === 200) {
      Toast.show({ content: '连接服务器成功', duration: 1000 })
      // 初始化通知
      notify = new Notify({
        effect: 'flash',
        interval: 500,
        disableFavicon: true,
      })
    }
  }

  function handleMatchResult(wsMsg) {
    // console.log(wsMsg)
    if (wsMsg.code === 200) {
      dialogRef.current = Dialog.show({
        closeOnMaskClick: true,
        content: (
          <UserProfile
            user={wsMsg.data}
            showOpt={true}
            isShowRematch={true}
            hideRemark={true}
            onRematch={onRematch}
            createChatByMatch={createChatByMatch(wsMsg.data.match_id)}
          />
        ),
      })
      // 通知
      if (document.visibilityState !== 'visible') {
        notify.setTitle('【已匹配】' + appInfo.titleSuffix)
      }
      if (window.openNotifySound) {
        audioRef.current?.play()
      }
    } else {
      Toast.show({ content: wsMsg.msg, icon: 'fail' })
    }
  }

  function handleSetOffline(wsMsg) {
    closeWebSocket()
    // 另一处登录该账号时，被通知下线
    Modal.alert({
      title: '重复登录',
      content: '该账号已在其他地方登录，请勿重复登录或打开多个窗口。如果没有重复登录请关闭所有窗口后重新进入',
      onConfirm: () => {
        window.location.reload()
      },
    })
  }

  function handleUserStatusChange(wsMsg) {
    const { uid, status } = wsMsg.data
    updateUserInfoCache(uid, { status: status })
  }

  function getLastMessage() {
    let messages = []
    Object.keys(messageMap || {}).forEach((chatid) => {
      Object.keys(messageMap[chatid] || {}).forEach((message_id) => {
        let message = messageMap[chatid][message_id]
        messages.push(message)
      })
    })
    messages.sort((a, b) => {
      return b.ts - a.ts
    })
    if (messages.length > 0) {
      return messages[0]
    }
    return null
  }

  async function handleLoadNewMessages(wsMsg) {
    let lastMsgId = null
    let lastMsgTs = wsMsg.data.disconnectTs
    const lastMsg = getLastMessage()
    if (lastMsg) {
      lastMsgId = lastMsg.id
      lastMsgTs = lastMsg.ts
    }
    const { code, data } = await loadNewMessagesApi(lastMsgId, lastMsgTs)
    if (code !== 200) return
    if (data) {
      data.forEach((message) => {
        recvMsg(message)
      })
    }
  }

  function handleRecvMsg(wsMsg) {
    recvMsg(wsMsg.data)
  }

  async function recvMsg(message) {
    const chatMap1 = store.getState().chatMap
    let isGroupChat = false
    // console.log(message)
    insertMsgs([message])
    // 处理chatMap中没有当前聊天的情况
    const { chatid, msg_type, msg_content, ts } = message
    if (!chatMap1[chatid] || chatMap1[chatid].otherLeave) {
      const { code, data } = await getChatInfoApi(chatid, message.is_group_chat)
      if (code !== 200) return
      if (data) {
        addChat(data)
        isGroupChat = data.is_group_chat || false
      }
    } else {
      isGroupChat = chatMap1[chatid].isGroupChat || chatMap1[chatid].is_group_chat
      // 处理增加未读消息数目
      if (!currChat || currChat.chatid !== message.chatid) {
        if (userRef.current.id && message.uid !== userRef.current.id && !message.is_read) {
          changeUnreadCount(message.chatid, 1)
        }
      }
    }
    // 如果没用户信息，补充用户信息
    if (message.uid && !store.getState().userMap[message.uid]) {
      const resp = await getUserBaseInfoApi(message.uid, 'recvMsg')
      if (resp.code === 200) {
        setUserInfoCache(resp.data)
      }
    }
    // 处理最后一条消息
    setLastMsg(chatid, msg_type, msg_content, ts)
    if (message.uid !== userRef.current.id && !isGroupChat) {
      // 通知
      if (document.visibilityState !== 'visible') {
        notify.setTitle('【新消息】' + appInfo.titleSuffix)
      }
      if (window.openNotifySound) {
        audioRef.current?.play()
      }
    }
  }

  function handleDeleteChat(wsMsg) {
    const chatid = wsMsg.data?.chatid
    deleteChatMsgs(chatid)
    // 后端发的消息
    if (wsMsg.func !== 'delete_chat_manual') {
      otherDeleteChat(chatid)
      const msg = wsMsg.func === 'chat_is_deleted' ? '该聊天已被对方删除' : '您已被请出该群聊'
      Toast.show({ content: msg, duration: 2000, icon: 'fail' })
    } else {
      // 前段发的
      deleteChat(chatid)
    }
    setCurrChat(null)
  }

  function handleBlockChat(wsMsg) {
    Toast.show(wsMsg.msg)
  }

  async function openChat(selectChat) {
    // 判断chat是否已删除，如果已删除给提示
    console.log('openChat selectChat', selectChat)
    if (!selectChat) return
    if (selectChat.banType === 'permanent_ban') {
      Toast.show({ content: '对方因违反服务条款已被永久封禁', duration: 2000, icon: 'fail' })
      return
    }
    if (selectChat.otherLeave) {
      Toast.show({ content: '对方已删除和你的对话', duration: 2000, icon: 'fail' })
      return
    }

    const { code, data } = await getChatApi(selectChat.id, selectChat.isGroupChat || selectChat.is_group_chat)
    if (code !== 200) return
    if (!data || data.is_delete) {
      Toast.show({ content: '该聊天已被对方删除', duration: 2000, icon: 'fail' })
      otherDeleteChat(selectChat.chatid)
      deleteChatMsgs(selectChat.chatid)
      return
    }
    // 这里也得有，如果没刷新页面下对方删除了，缓存的数据会走进来
    if (data.ban_type === 'permanent_ban') {
      Toast.show({ content: '对方因违反服务条款已被永久封禁', duration: 2000, icon: 'fail' })
      return
    }
    if (data.other_leave) {
      Toast.show({ content: '对方已删除和你的对话', duration: 2000, icon: 'fail' })
      otherDeleteChat(selectChat.chatid)
      return
    }
    Object.assign(selectChat, data)

    // 提示打开了什么模式
    if (data.is_english || data.is_green) {
      const msg = '已开启' + (data.is_green ? ' 绿色' : '') + (data.is_english ? ' 英语' : '') + ' 模式'
      Toast.show({ content: msg, position: 'top' })
    }
    setShowUserInfo(false)
    setCurrChat(selectChat)
    if (!chatMap[selectChat.chatid]) {
      // 没有聊天 增加一个聊天
      addChat(selectChat)
    }
    // 如果该聊天没用户信息，补充用户信息
    if (!selectChat.title) {
      const resp = await getUserBaseInfoApi(selectChat.to_uid || selectChat.toUid, 'openChat')
      if (resp.code === 200) {
        setUserInfoCache(resp.data)
      }
    }
    if (data.is_group_chat && selectChat.chatid !== 'a7973381807c28c19ffccb3a73779c7a') {
      // 如果是群聊，需要获取群聊中用户的信息，更新在缓存中
      const resp = await getGroupChatUsersInfoApi(selectChat.id)
      if (resp.code !== 200) {
        Toast.show({ icon: 'fail', content: resp.msg })
      }
      setUserMapMany(resp.data)
      // 更新群组在线人数
      let onlineUids = []
      let memberUids = []
      resp.data.forEach((e) => {
        memberUids.push(e.id)
        if (e.status === 'online') {
          onlineUids.push(e.id)
        }
      })
      if (memberUids.length) setGroupUids(selectChat.id, memberUids, 'add', 'member')
      if (onlineUids.length) setGroupUids(selectChat.id, onlineUids, 'add', 'online')
    }
    // 将该聊天未读消息设置为0
    if (!cookie.load('is_debug')) {
      changeUnreadCount(selectChat.chatid, 0, true)
    }
  }

  async function openFeedback() {
    // 创建聊天
    const { code, data, msg } = await startChatApi('support', '')
    if (code !== 200) {
      Toast.show({ content: msg, icon: 'fail' })
      return
    }
    if (!data) return
    openChat(data)
  }

  function handleQuitChat() {
    if (!currChat) return
    const chatid = currChat.chatid
    if (!(currChat.is_group_chat || currChat.isGroupChat) && userRef.current.id) {
      // 拿单个chat未读消息数，更新本地信息
      const messages = messageMap[chatid]
      let unreadMsgIds = []
      Object.keys(messages || {}).forEach((messageId) => {
        const message = messages[messageId]
        if (message.uid !== userRef.current.id && message.msgStatus === 'isSend') {
          unreadMsgIds.push(message.id)
        }
      })
      // 将所有未读消息更新成已读
      if (!cookie.load('is_debug')) {
        const toUid = chatMap[chatid]?.toUid || chatMap[chatid]?.to_uid
        if (unreadMsgIds.length) {
          wsSetMsgsIsRead(userRef.current.id, toUid, chatid, unreadMsgIds)
        }
        changeUnreadCount(chatid, 0, true)
        wsSetChatIsRead(userRef.current.id, toUid, chatid)
      }
    } else {
      changeUnreadCount(chatid, 0, true)
    }
    setCurrChat(null)
  }

  function handleShowTips() {
    Dialog.alert({
      title: '提示',
      maskStyle: { opacity: 0.5 },
      content: <Tips />,
      closeOnMaskClick: false,
    })
  }

  async function handleClearChats() {
    if (Object.keys(chatMap || {}).length === 0) {
      Toast.show({ content: '私聊列表是空的，无需清理' })
      return
    }
    const result = await Dialog.confirm({
      title: '确认删除吗？',
      content: '除了已收藏的私聊外，所有私聊双方都将被删除',
    })
    if (result) {
      const { code, data } = await clearChatsApi()

      if (code !== 200) return
      if (data) {
        clearChats(data)
        clearMsgs(data)
        Toast.show('已清空私聊列表')
      } else {
        Toast.show('没有可删除的私聊')
      }
    }
  }

  const allUnreadMsgCount = useMemo(() => {
    let allUnreadMsgCount = 0
    Object.keys(chatMap || {}).forEach((chatid) => {
      const chat = chatMap[chatid]
      // 过滤掉已打开的聊天
      if (!currChat || chatid !== currChat.chatid) {
        allUnreadMsgCount = allUnreadMsgCount + chat.unreadMsgCount
      }
    })
    return allUnreadMsgCount
  }, [chatMap, currChat])

  const pageMap = [
    {
      path: '/discover',
      title: '发现' + appInfo.titleSuffix,
      page: <Discover openChat={openChat} broadcast={broadcast} />,
    },
    {
      path: '/match',
      title: '遇见' + appInfo.titleSuffix,
      page: <MatchPanel openChat={openChat} broadcast={broadcast} />,
    },
    {
      path: '/chat',
      title: '对话' + appInfo.titleSuffix,
      page: <DialogList openChat={openChat} handleShowTips={handleShowTips} broadcast={broadcast} />,
    },
    {
      path: '/settings',
      title: '设置' + appInfo.titleSuffix,
      page: <UserSettings openFeedback={openFeedback} broadcast={broadcast} />,
    },
    {
      path: '/tos',
      title: '服务条款' + appInfo.titleSuffix,
      page: <TermOfService />,
    },
    // {
    //   path: '/active-by-exam',
    //   title: '答题激活' + appInfo.titleSuffix,
    //   page: <ActiveByExam />,
    // },
    // {
    //   path: '/active-by-wx',
    //   title: '关注微信公众号激活' + appInfo.titleSuffix,
    //   page: <ActiveByWx />,
    // },
    {
      path: '/vip',
      title: 'VIP服务' + appInfo.titleSuffix,
      page: <VipService />,
    },
    // {
    //   path: '/banned_users',
    //   title: '违规处理公示' + appInfo.titleSuffix,
    //   page: <BannedUsers />,
    // },
  ]

  const bottomHeight = navigator.standalone ? '20px' : '0px'

  function handleCreateGroupChat() {
    setCreateGroupChatVisible(true)
  }

  const actions = [
    // { key: 'create_group_chat', text: '创建群聊' },
    { key: 'clear_chats', text: '清空私聊' },
  ]

  function handleAction(action, e) {
    if (action.key === 'create_group_chat') {
      handleCreateGroupChat()
    } else if (action.key === 'clear_chats') {
      handleClearChats()
    }
  }

  function onRematch() {
    if (dialogRef.current) {
      dialogRef.current.close()
    }
    setTimeout(() => {
      PubSub.publish('message', { func: 'start_match_manual' })
    }, 300)
  }

  function createChatByMatch(matchId) {
    return async () => {
      const { code, data, msg } = await startChatApi('match', matchId)
      if (code !== 200) {
        Toast.show({ content: msg, icon: 'fail' })
        return
      }
      openChat(data)
      if (dialogRef.current) {
        dialogRef.current.close()
      }
    }
  }

  return (
    <div className="page-wrapper-outer" style={{ height: '100%' }}>
      <Register visible={registerVisbile} setVisible={setRegisterVisbile} initSystem={initSystem} />
      <audio ref={audioRef} src="/sounds/new_msg.mp3"></audio>
      <div
        className="page-wrapper"
        style={{
          height: '100%',
          display: currChat ? 'none' : 'flex',
          flexDirection: 'column',
        }}>
        <Routes>
          <Route path="/" element={<Navigate to="/discover" replace />} />
          <Route path="/crm" element={<CrmPage />} />
          <Route path="/active" element={<ActivePage />} />
          <Route path="/debug" element={<DebugPage />} />
          <Route path="/promote" element={<PromotePage />} />
          {pageMap.map((item) => {
            return (
              <Route
                key={item.path}
                path={item.path}
                element={
                  <>
                    <div className="page-title">
                      {item.path === '/chat' && (
                        <span style={{ position: 'absolute', left: 0 }}>
                          <Button color="warning" fill="none" size="mini" onClick={handleShowTips}>
                            <Help theme="outline" size="20" fill="var(--theme-text-color)" />
                          </Button>
                        </span>
                      )}
                      <span>{item.title}</span>
                      {item.path === '/chat' && (
                        <span style={{ position: 'absolute', right: 0 }}>
                          <Popover.Menu actions={actions} placement="topRight" onAction={handleAction} trigger="click">
                            <MoreOne
                              style={{ marginRight: 10, cursor: 'pointer' }}
                              theme="outline"
                              size="24"
                              fill="var(--theme-text-color)"
                            />
                          </Popover.Menu>
                        </span>
                      )}
                    </div>
                    <div style={{ flex: 1, overflowY: 'auto' }}>{item.page}</div>
                  </>
                }
              />
            )
          })}
        </Routes>
        {showNav && <Navbar allUnreadMsgCount={allUnreadMsgCount} />}
        <div style={{ height: bottomHeight, backgroundColor: 'var(--theme-window-bgcolor)' }} />
      </div>

      {currChat && (
        <DialogDetail
          allUnreadMsgCount={allUnreadMsgCount}
          chatid={currChat.chatid}
          currChat={currChat}
          showUserInfo={showUserInfo}
          onClickBack={handleQuitChat}
          broadcast={broadcast}
          setGroupUids={setGroupUids}
          setUserMapMany={setUserMapMany}
        />
      )}

      <Modal
        closeOnMaskClick={false}
        title="创建群聊"
        visible={createGroupChatVisible}
        content={
          <GroupChatEditor
            panelType="create"
            addChat={addChat}
            onFinish={() => {
              setCreateGroupChatVisible(false)
            }}
          />
        }
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
    chatMap: state.chatMap,
    messageMap: state.messageMap,
  }
}

function mapDispatchProps(dispatch) {
  return {
    setUser: (user) => dispatch(setUser(user)),
    updateMsgsStatus: (chatid, messageId, status) => dispatch(updateMsgsStatus(chatid, messageId, status)),
    insertMsgs: (messages) => dispatch(insertMsgs(messages)),
    addChat: (chat) => dispatch(addChat(chat)),
    changeUnreadCount: (chatid, count, isCover) => dispatch(changeUnreadCount(chatid, count, isCover)),
    clearChats: (chatids) => dispatch(clearChats(chatids)),
    deleteChat: (chatid) => dispatch(deleteChat(chatid)),
    otherDeleteChat: (chatid) => dispatch(otherDeleteChat(chatid)),
    setUserInfoCache: (user) => dispatch(setUserInfoCache(user)),
    updateUserInfoCache: (uid, data) => dispatch(updateUserInfoCache(uid, data)),
    setUserMapMany: (user_infos) => dispatch(setUserMapMany(user_infos)),
    clearMsgs: (chatids) => dispatch(clearMsgs(chatids)),
    deleteChatMsgs: (chatid) => dispatch(deleteChatMsgs(chatid)),
    deleteMsg: (chatid, messageId) => dispatch(deleteMsg(chatid, messageId)),
    updateChat: (chatid, data) => dispatch(updateChat(chatid, data)),
    setGroupUids: (chatid, uids, opt, uidType) => dispatch(setGroupUids(chatid, uids, opt, uidType)),
    setLastMsg: (chatid, lastMsgType, lastMsgContent, lastMsgDate) =>
      dispatch(setLastMsg(chatid, lastMsgType, lastMsgContent, lastMsgDate)),
  }
}

export default connect(mapStateToProps, mapDispatchProps)(Layout)
